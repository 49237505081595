@import url('https://fonts.googleapis.com/css?family=Shrikhand|Raleway');

.drop{
   background-color: black;
   position: absolute;
   right: 5%;
   color: #2E2C2F;
   border-color: #2E2C2F;
}

.drop:hover, .drop:active, .show>.drop.dropdown-toggle, .show>.drop.dropdown-toggle:focus, .drop:focus{
    background-color: #2E2C2F;
    border-color: #2E2C2F;
    box-shadow: 0 0 0 0.2rem #2E2C2F;
}

.dropcard{
    font-family: Shrikhand;
    color: white;
    border-color: #2E2C2F;
    font-size: 20px;
    background-color: transparent;
}

.dropmenu{
    background-color: black;
    border-color: #2E2C2F;
    text-align: center;
}

.dropcard:hover{
    color: #2E2C2F;
    background-color:transparent;
}

@media only screen and (max-width: 768px) {
    
}