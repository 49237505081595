@import url('https://fonts.googleapis.com/css?family=Shrikhand|Raleway');

.editsubmit{
    background-color: transparent;
    position: absolute;
    right: 5%;
    top: 5%;
    font-family: raleway;
    color: #2E2C2F;
    border-color: #2E2C2F;
}

.edate{
    font-family: Shrikhand;
    color: white;

    font-size: 1.5em;
    width: 60%;
    background-color: transparent;
    border-color: transparent;
}

.topic{
    font-family: Shrikhand;
    color: #2E2C2F;
    left: 15%;
    font-size: 2em;
    width: 75%;
    background-color: transparent;
    border-color: transparent;

}

.detail{
    background-color: transparent;
    font-family: Raleway;
    border-color: transparent;
    color: white;
    width: 85%;
    font-size: 1.5em;
}

.ocurr{
    border-color: white;
    border-width: 1px;
    color: white;
    font-family: Raleway;
    background-color: transparent;
    position: absolute;
    width: 6%;
    top: 20%;
    right: 5%;
}

.ocrlbl{
    color:white;
    position: absolute;
    font-family:raleway;
    top: 15%;
    right: 5%;
}

.radiohead{
    font-family: Raleway;
    font-size: 1.5em;
    color: white;
    position: relative;
    left: 5%;
}

.radioparty{
    font-family: Raleway;
    background-color: #2E2C2F;
    position: relative;
    left: 5%;
    color: white;
}

.radioparty:checked{
    font-family: Raleway;
    background-color: white;
    position: relative;
    left: 15%;
    color: blue;
    display: none;
}

.archive{
    position: relative;
    left: 5%;
}

.complete{
    position: relative;
    left: 7.5%;
    color: red;
    border-color: Red;
}