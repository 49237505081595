@import url('https://fonts.googleapis.com/css?family=Shrikhand|Raleway');

.crmwidget{
    width: 50%;
    max-width: 1vw;
}

.crmwidgetspace{
    padding-bottom: 1%;
    padding-right: 1%;
    padding-left: 1%;
    background-color: #6e5794;
    /* background-color: #0b0b0b; */
}

.plan{
    background-color: transparent;
    font-family: Raleway;
    border-color: transparent;
    color: white;
    width: 85%;
    font-size: 1.75em;
}

.plansubmit{
    background-color: transparent;
    position: absolute;
    right: 5%;
    top: 15%;
    font-family: raleway;
    color: white;
}

.date{
    font-family: raleway;
    color: white;
    background-color: transparent;
    border-color: transparent;
}

.note{
    font-family: raleway;
    color: white;
}

.newnote{
    background-color: transparent;
    border-color: white;
    color: white;
    font-family: raleway;
}

.printbtn{
    background-color: transparent;
    font-family: raleway;
    color: #2E2C2F;
    border-color: #2E2C2F;
    border-width: 1px;
    position: absolute;
    top: 25%;
    right: 5%;
}

.bt1{
    background-color: transparent;
    border-color: #2E2C2F;
    font-family: raleway;
    color: #2E2C2F;
    border-width: 1px;
}

.bt2{
    background-color: transparent;
    border-color: white;
    font-family: raleway;
    color: white;
    border-width: 1px;
}

.circle{
    display:block;
    width:6em;
    height:6em;
    border-radius:3em;
    border-color: #2E2C2F;
    font-size:10px;
    color:#fff;
    text-align:center;
    background:#000;
}

.online{
    display:block;
    width:3em;
    height:3em;
    border-radius:3em;
    border-color: black;
    font-size:10px;
    color: black;
    text-align:center;
    background: greenyellow;
}

.offline{
    display:block;
    width:3em;
    height:3em;
    border-radius:3em;
    border-color: black;
    font-size:10px;
    color:#fff;
    text-align:center;
    background:red;
}

.view{
    display:block;
    width:6em;
    height:6em;
    border-radius:3em;
    border-color: #2E2C2F;
    font-size:10px;
    color:#fff;
    text-align:center;
    background:#2E2C2F;
}

.fullnote:hover td{
    background-color: #2E2C2F;
    color: white;
    border-color:white;
}

.fullnote:hover .bt2{
    background-color: white;
    color: #00ff00;
    border-color:white;
}

.fullnote:hover .bt1{
    background-color: white;
    color: #8542F2;
    border-color:white;
}