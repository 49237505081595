@import url('https://fonts.googleapis.com/css?family=Open+Sans|Courgette');

.blogtitle{

padding-top: 1%;


font-family: Courgette;
font-style: normal;
font-weight: normal;
font-size: 50px;
line-height: 95px;
text-align: center;

color: #5E4352;
}

.blogpost{
background-color: #0e2e3b;
border: 10px solid  rgb(45, 54, 58);
}

.blogttl{
    color: white;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 95px;

}

.blogdate{
    color: white;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px; 
}

.align{
    text-align: center;
}

.blogimage{
    border: 10px solid  #272838; 
    width: 30vw;
}

.aller{
    font-family: "Raleway";
    text-align: right;

}

.blogdetes{
    width: 100%;
    height: fit-content;
    background-color: transparent;
    color: white;
    font-family: 'Raleway';
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 5%;
    overflow-wrap: break-word;
    border: none;
    white-space: pre-wrap;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .blogtitle {
        font-size: 2rem;
    }}