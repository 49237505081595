@import url('https://fonts.googleapis.com/css?family=Open+Sans|Courgette');
@font-face {
    font-family: 'Lust';
    src: url('/src/lust-slimfont-family/Lust Slim Display Didone.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.title {

padding-top: 2.5%;

font-family: Courgette;
font-style: normal;
font-weight: normal;
font-size: 50px;
line-height: 60px;
text-align: center;

color: #FFFFFF;

text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

.line1 {
/* Line 1 */

position: absolute;
left: 0%;
right: 0%;
top: 55%;
bottom: 0%;

width: 30%;
border: 1px solid #2E2C2F;
transform: rotate(90deg);

}

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: blue orange;
  }
  
  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }
  
  *::-webkit-scrollbar-track {
    background: black;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #2E2C2F;
    border-radius: 20px;
    border: 3px solid black;
  }

.prot{
    
    position: absolute;
    left: 0%;
    right: 50%;
    top: 70%;
    bottom: 0%;

    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 4rem;
    line-height: 75px;
    text-align: center;
    color: #FFFFFF;
}

.pert{
    
    position: absolute;
    left: 50%;
    right: 00%;
    top: 70%;
    bottom: 0%;

    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 4em;
    line-height: 75px;
    text-align: center;
    color: #FFFFFF;
}

.proi{
    max-width: 9rem;
    position: absolute;
    left: 20%;
    top: 30%;
    text-align: center;

}

.peri{
    max-width: 10rem;
    position: absolute;
    left: 70%;
    top: 30%;
    text-align: center;
}

.proi:hover,.peri:hover {
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
transform: scale(1.4);
}

.span2 {
    font-size:125%;
    color: #5E4352;
}

.hiddenbutton {
    visibility: hidden;
}

.sender{
    position: absolute;
    left: 00%;
    right: 00%;
    top: 90%;
    bottom: 0%;
}

.sendinput {
    margin-left: 1em;
    margin-right: 5%;
    width: 70%;
    font-size: 1.5em;
}

.sendbt{
    font-size: 1.5em;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .title {
        font-size: 2rem;
    }

    .prot {
        font-size: 1.5em;
    }
    .pert {
        font-size: 1.5em;
    }

    .proi {
        left: 10%
    }
    .peri {
        left: 60%
    }
}